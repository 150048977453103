m-font-face( $font-family = "Arial", $file-path = "", $local-name = "", $weight, $style = normal) {
	@font-face {
		font-family: $font-family;
		src: url($file-path + '.eot');
		src: local($local-name),
			 url($file-path + '.eot?#iefix') format('embedded-opentype'),
			 url($file-path + '.woff2') format('woff2'),
			 url($file-path + '.woff') format('woff'),
			 url($file-path + '.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}


m-font-face("GothamPro", "../fonts/GothamProRegular/GothamProRegular", "GothamPro Regular", 400);
m-font-face("GothamPro", "../fonts/GothamProMedium/GothamProMedium", "GothamPro Medium", 500);
m-font-face("GothamPro", "../fonts/GothamProBlack/GothamProBlack", "GothamPro Black", 900);
m-font-face("AvenirNextCyr", "../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium", "AvenirNextCyr Medium", 500);
m-font-face("AvenirNextCyr", "../fonts/AvenirNextCyr-Bold/AvenirNextCyr-Bold", "AvenirNextCyr Bold", 700);


@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?xet8t4');
  src:  url('../fonts/icomoon/icomoon.eot?xet8t4#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?xet8t4') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?xet8t4') format('woff'),
    url('../fonts/icomoon/icomoon.svg?xet8t4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bitcoin:before {
  content: "\e900";
  color: #f7931a;
}
.icon-check:before {
  content: "\e901";
  color: #32c5ff;
}
.icon-done:before {
  content: "\e902";
  color: #fff;
}
.icon-dropdown:before {
  content: "\e903";
  color: #bfc0c2;
}
.icon-euro:before {
  content: "\e904";
  color: #32c5ff;
}
.icon-mastercard .path1:before {
  content: "\e905";
  color: rgb(255, 95, 0);
}
.icon-mastercard .path2:before {
  content: "\e906";
  margin-left: -1.625em;
  color: rgb(235, 0, 27);
}
.icon-mastercard .path3:before {
  content: "\e907";
  margin-left: -1.625em;
  color: rgb(247, 158, 27);
}
.icon-summary .path1:before {
  content: "\e908";
  color: rgb(8, 132, 181);
}
.icon-summary .path2:before {
  content: "\e909";
  margin-left: -1.5791015625em;
  color: rgb(13, 167, 228);
}
.icon-summary .path3:before {
  content: "\e90a";
  margin-left: -1.5791015625em;
  color: rgb(50, 197, 255);
}
.icon-summary .path4:before {
  content: "\e90b";
  margin-left: -1.5791015625em;
  color: rgb(255, 255, 255);
  opacity: 0.6;
}
.icon-summary .path5:before {
  content: "\e90c";
  margin-left: -1.5791015625em;
  color: rgb(13, 167, 228);
}
.icon-summary .path6:before {
  content: "\e90d";
  margin-left: -1.5791015625em;
  color: rgb(13, 167, 228);
}
.icon-summary .path7:before {
  content: "\e90e";
  margin-left: -1.5791015625em;
  color: rgb(13, 167, 228);
}
.icon-summary .path8:before {
  content: "\e90f";
  margin-left: -1.5791015625em;
  color: rgb(13, 167, 228);
}
.icon-summary .path9:before {
  content: "\e910";
  margin-left: -1.5791015625em;
  color: rgb(13, 167, 228);
}
.icon-summary .path10:before {
  content: "\e911";
  margin-left: -1.5791015625em;
  color: rgb(13, 167, 228);
}
.icon-visa:before {
  content: "\e912";
  color: #fff;
}


