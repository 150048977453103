//main styles
.main-wrapper
	padding 0 0 0 0
	min-width $min-width
	width 100%
	position relative
	overflow hidden
	min-height 100%
	display flex
	flex-direction column
	justify-content flex-start	

.wrapper
	min-width $min-width
	max-width $max-width
	padding 0 40px 0 40px
	margin 0 auto
	position relative

/* titles */
p
	font(20px, 30px, $white, $base-font-family, 400)
	margin 0 0 15px 0
	padding 0

h1
	font($font-size-h1, $line-height-h1, $title-color, $base-font-family, 700)
	margin 0 0 15px 0
	padding 0

h2
	font($font-size-h2, $line-height-h2, $title-color, $base-font-family, 700)
	margin 0 0 15px 0
	padding 0

h3
	font($font-size-h3, $line-height-h3, $title-color, $base-font-family, 500)
	margin 0 0 15px 0
	padding 0

h4
	font($font-size-h4, $line-height-h4, $text-color, $base-font-family, 500)
	margin 0 0 15px 0
	padding 0

/* styles */
.main
	min-width $min-width
	width 100%
	order 2
	flex-grow 1
	background-color $accent3
	&_inner 
		background-color transparent

.btn 
	display inline-flex
	align-items center
	justify-content center
	text-align center
	height 60px
	padding 10px 38px
	color $white
	font-weight 700
	border-radius 10px
	text-transform uppercase
	background-color $accent
	&:hover 
		background-color lighten($accent, 20%)
	&_button 
		border none
	&_white
		position relative
		height 50px
		padding 10px 16px
		border 2px solid rgba($white, .5)
		background-color transparent
		overflow hidden
		&:after
			content ''
			position absolute
			height 100px
			width 20px
			transform skew(-25deg) translateY(-50%)
			left -100px
			top 50%
			background-color $white
		&:hover
			transform translateY(-5px)
			&:after
				left 100%
				transition all .25s linear


.section
	&-head 
		padding 154px 0 70px
		position relative
		background url(../img/main/head-bg.png) center no-repeat
		background-size cover
		&:after 
			content ''
			position absolute
			left 0
			bottom 0
			right 0
			width 100%
			height 512px
			background-image linear-gradient(180deg, rgba(24, 181, 243, 0) 0%, $accent3 100%)
		.wrapper
			z-index 1
		h1 
			margin-bottom 32px
		p
			margin 0 0 53px 0
			line-height 35px
	&-how
		padding 70px 0
		h2
			margin-bottom 50px	
			text-align center
		h3
			margin 30px 0 0 0
	&-main 
		padding 78px 0 112px
		h2
			margin-bottom 32px
		p	
			margin-bottom 33px
			line-height 35px
			&:last-child 
				margin-bottom 0
	&-foot
		padding 150px 0 325px
		background url(../img/main/footer-bg.jpg) center no-repeat
		background-size cover
		h2
			margin-bottom 52px
			text-align center
	&-inner 
		padding 100px 0

.head
	&-wrap
		display flex
		justify-content space-between
		align-items flex-start
	&-left
		width calc(53% - 50px)
		padding-top 53px
	&-right 
		width calc(47% - 50px)
	&-bottom 
		display flex
		.visa-ico 
			margin-right 16px
			font-size 20px
		.master-card-ico
			margin-right 20px
			font-size 24px
		&__text
			color #ffffff

.bitcoin
	&-buy
		padding 50px 50px 40px
		box-shadow 0 20px 60px rgba(13, 21, 63, 0.15)
		border-radius 15px
		background-color $white
	&-row
		display flex
		align-items center
		.btn 
			flex-shrink 0
			margin-right 34px
	&-text
		margin-top 20px
		font-size 12px
		line-height 20px		
		color rgba($gray2, .7)

.box-field
	margin-bottom 20px
	&:last-child 
		margin-bottom 0 
	&-wrap 
		margin-bottom 30px
	&__label
		display flex
		justify-content space-between
		margin-bottom 10px
		font-size 12px
		line-height 20px
		&-text 
			color $gray
		&-subtext
			color rgba($gray2, .7)
	&__input
		&_abs 
			position relative
			.form-control
				padding 18px 70px 17px 20px
		&_z
			z-index 1
	&s 
		display flex
		justify-content space-between
		flex-wrap wrap
		margin-bottom 20px
		&_1
			.box-field 
				width 100%
		&_2 
			.box-field 
				width calc(50% - 10px)
				margin-bottom 0

.form-control 
	height 60px
	width 100%
	padding 18px 20px 17px
	border-radius 10px
	color $gray
	border none 
	background-color $white1

.dropdown-abs
	position absolute
	right 15px
	top 50%
	transform translateY(-50%)

.how
	&__list 
		position relative
		display flex
		justify-content space-between
		max-width 1090px
		margin 0 auto
		counter-reset item
		&:after 
			content ''
			position absolute
			top 30px
			left 50%
			height 1px
			width calc(100% - 300px)
			transform translateX(-50%)
			border 2px dashed rgba(#202327, .5)
	&__item 
		position relative
		z-index 1
		width calc(33.33% - 75px)
		counter-increment item
		text-align center
	&-count
		position relative
		height 60px
		width 60px
		margin 0 auto
		background-color $black
		border-radius 50%
		display flex
		align-items center
		justify-content center
		font-size 30px
		font-weight 700
		line-height 40px
		&:after 
			content counter(item)
			display inline-block
			font-size 30px
			font-weight 700
			line-height 40px
			color $white

.main
	&-box
		display flex
		justify-content space-between
		align-items flex-start
		&_1 
			.main-pic
				padding-top 13px
				&:after
					left 49%
					top 60%
					width 537px
					height 315px
					background-image url(../img/main/schema-1.png)
		&_2
			.main-pic
				width calc(50% - 15px)
				&:after   
					left 49%
					top 51%
					width 571px
					height 308px
					background-image url(../img/main/schema-2.png)
			.main-text
				padding-top 127px
				width calc(50% - 60px)
		&_3 
			.main-pic
				width calc(50% - 100px)
				margin-top -25px
				&:after
					top 32%
					left 51%
					width 557px
					height 373px
					background-image url(../img/main/schema-3.png)
			.main-text
				padding-top 20px
		&_reverse 
			flex-direction row-reverse
		&_mb
			margin-bottom 20px
	&-text 
		width calc(50% - 15px)
	&-pic
		position relative
		width calc(50% - 60px)		
		&:after
			content ''
			pointer-events none
			position absolute
			left 50%
			top 50%
			transform translate(-50%, -50%)
			background-position center
			background-repeat no-repeat
			background-size contain
		img 
			position relative
			z-index 1
			margin 0 auto
	&-svg-rows
		display flex
		& > div 
			margin-right 20px
			&:last-child 
				margin-right 0
.visa-ico
	font-size 20px
.master-card-ico
	font-size 24px
	&_mod
		.icon-mastercard .path1:before
			 color #8e8ae6
		.icon-mastercard .path2:before
			color #005fde
		.icon-mastercard .path3:before
			color #e52836

.foot 
	&-top
		display flex
		justify-content space-between
		max-width 1050px
		margin 0 auto 57px
	&-text
		width calc(50% - 70px)
		margin-bottom 0
		text-align center
	&-btn 
		text-align center

.order
	&-wrap
		display flex
		justify-content space-between
		align-items flex-start
		&.active 
			.order-box 
				position static
				pointer-events auto 
				opacity 1
				z-index auto
	&-left
		width calc(53% - 50px)
	&-right
		width calc(47% - 50px)
	&-box
		position absolute
		opacity 0
		z-index -111
		pointer-events none
		padding 50px
		box-shadow 0 30px 100px rgba(155, 161, 181, 0.15)
		border-radius 15px
		background-color $white
		h4 
			margin-bottom 0
	&-top
		display flex
		align-items center
		margin-bottom 20px
	&-ico 
		margin-right 15px
		font-size 20px
	&-table 
		margin-bottom 30px
		padding-bottom 20px
		border-bottom 1px solid #eff2fb
		table
			width 100%
			color $gray
			td
				width 50%
				padding 10px 0
				&.mod 
					text-align right
					text-transform uppercase
	&-total
		display flex
		justify-content space-between
		align-items center
		margin-bottom 30px
		font-size 30px
		line-height 40px
		font-weight 700
		&__count
			text-transform uppercase
	&-text
		span 
			color $accent2


.step
	&__list 
		counter-reset step
	&__item
		margin-bottom 28px
		counter-increment step
		padding 30px
		border-radius 15px
		border 1px solid #eff2fb
		&:last-child 
			margin-bottom 0
		&.complete
			h4
				color #333
				&:before
					content '\e901'
					font-size 18px
					font-family 'icomoon'
					color #fff
					background-color #23c4ff
		&.active
			h4
				margin-bottom 40px
				color #333
				&:before
					background-color #23c4ff
					color #fff
			.step-cont
				position static
				pointer-events auto
				opacity 1
				z-index auto
		h4 
			position relative
			margin-bottom 0
			padding 10px 0 10px 70px
			color #d0d4e3
			transition $transition
			&:before
				content counter(step)
				display flex
				align-items center
				justify-content center
				position absolute
				left 0
				top 50%
				transform translateY(-50%)
				width 50px
				height 50px
				border-radius 50%
				color #d0d4e3
				font-size 25px
				font-weight 700
				line-height 35px
				background-color #f5f6fa
				transition $transition
		.bitcoin-buy
			box-shadow none
			padding 0
		.btn
			pointer-events auto
			padding 10px 20px
			margin-right 28px
			min-width 245px
			background-color rgba($accent, 1)
			&:hover 
				background-color #47c4f5
			&.freeze 
				pointer-events none
				background-color rgba($accent, .5)

	&-cont 
		position absolute
		pointer-events none
		opacity 0
		z-index -111
.icon-abs 
	position absolute
	display flex
	align-items center
	justify-content center
	padding 8px 5px
	right 15px
	top 50% 
	transform translateY(-50%)
	border-radius 8px
	background-color #fff
	font-size 14px
	.icon-visa:before 
		background-image linear-gradient(270deg, #044c9e 0%, #202565 100%)
		-webkit-background-clip text
		-webkit-text-fill-color transparent
		color #044c9e
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) 
	.icon-abs .icon-visa:before
		background transparent

.checkbox 
	position: absolute
	z-index: -1
	opacity: 0
	margin: 10px 0 0 20px

.checkbox + label 
	cursor: pointer
	position: relative
	display: inline-block
	padding: 0 0 0 45px
	font-size: 12px
	line-height: 20px

.checkbox + label:before 
	content: ''
	position: absolute
	top: 5px
	left: 0
	width: 30px
	height: 30px
	border-radius: 5px
	border: 2px solid $white1
	transition: $transition

.checkbox + label:after 
	content: "\e901"
	position: absolute
	opacity: 0
	top: 11px
	left: 9px
	font-family: 'icomoon'
	font-size: 10px
	color: $accent2
	transition: $transition

.checkbox:checked + label:after 
	opacity: 1

.jq-selectbox
	border-radius 8px
	&.focused 
		.jq-selectbox__select 
			border none
	&.active
		.jq-selectbox__select 
			box-shadow none
	&.opened
		&.dropdown
			border-radius  8px 8px 0 0
			.jq-selectbox__dropdown
				border-radius 0 0 8px 8px
		&.dropup 
			border-radius 0 0 8px 8px
			.jq-selectbox__dropdown
				border-radius  8px 8px 0 0

.jq-selectbox__select
	padding 7px 20px 7px 10px
	height 30px
	min-width 52px
	border-radius 0
	border none
	background transparent
	box-shadow none
	text-shadow none
	&:active 
		background transparent
		box-shadow none
	&:hover 
		background transparent
.jq-selectbox__trigger
	width 28px
	border-left none
	&:after
		content '\e903'
		position absolute
		left 50%
		top 50%
		transform translate(-50%, -50%)
		font-family 'icomoon'
		line-height 1
		font-size 5px
		color #bfc0c2
.jq-selectbox__trigger-arrow
	display none
.jq-file__browse, .jq-file__name, .jq-selectbox__select
	font-size $base-font-size
	line-height 1
	font-family $base-font-family
.jq-selectbox__dropdown, .jq-selectbox__search input
	border none
.jq-selectbox
	&__dropdown
		font-size $base-font-size
		line-height 1
		font-family $base-font-family
		border-radius 0 0 8px 8px
		overflow hidden
	li
		min-height 30px
		line-height 1
		padding 7px 10px
		color $text-color
		border-radius 0
		&:hover
			background-color $accent
.sel-icon 
	&_eur
		background $white url(../img/icons/euro.svg) no-repeat 10px 6px
		background-size 16px
	&_btc
		background $white url(../img/icons/bitcoin.svg) no-repeat 10px 4px
		background-size 16px