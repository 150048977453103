/*==========  Desktop First  ==========*/

// /* Large Devices, Wide Screens */
@media (max-width : 1280px) 
	.bitcoin-row
		display block
	.bitcoin-row
		text-align center
	.bitcoin-row .btn
		width 100%
		margin-right 0
		margin-bottom 20px
	.bitcoin-text
		text-align center
	.box-field__label
		flex-direction column

@media (max-width : 1100px) 
	.how__list:after
		width calc(100% - 230px)
	.order-left,
	.order-right 
		width calc(50% - 25px)

// /* Medium Devices, Desktops */
@media (max-width : 991px)
	.wrapper
		padding 0 20px
	.header
		padding 25px 0
	.section
		&-head
			padding 120px 0 60px
	.head-wrap
		display block
	.head-left
		width auto
		margin-bottom 30px
		padding-top 0
	.head-right
		max-width 500px
		width 100%
		margin 0 auto
	h1 
		font-size 40px
		line-height 50px
	h2
		font-size 40px
		line-height 50px
	.how__item
		width calc(33.33% - 0px)
	.main-box_3 .main-pic
		margin-top 0
	.main-box_2 .main-text, .main-box_3 .main-text, .main-box_1 .main-pic
		padding-top 0
	.main-box
		align-items center
		margin-bottom 50px
		&:last-child 
			margin-bottom 0
	.main-box_3 .main-pic
		width calc(50% - 40px)
	.foot-text
		width calc(50% - 20px)
	.section-foot	
		padding 100px 0 370px
	.footer-wrap
		flex-wrap: wrap
	.footer-right
		position relative
		order: -1
		margin-bottom 30px
		width 100%
		max-width: 100%
	.footer-text
		text-align center
		padding 0 70px
		margin-right 0
	.footer-logo 
		position absolute
		top 0
		right 0
	.footer-left 
		margin 0 auto
		text-align center
	.footer-text br
		display none
	.how__list:after
		width calc(100% - 260px)
	.order-wrap
		flex-direction column-reverse
		align-items inherit
	.order-left, .order-right
		width auto
	.order-right 
		margin-bottom 50px
	.section-inner
		padding 60px 0
// /* Small Devices, Tablets */
@media (max-width : 767px)
	h1 
		font-size 30px
		line-height 40px
	h2
		font-size 30px
		line-height 40px
	h3
		font-size 20px
		line-height 30px
	h4
		font-size 16px
		line-height 26px
	p
		font-size 16px
		line-height 24px
	.section-head p
		line-height 28px
	.head-bottom
		flex-wrap wrap
	.bitcoin-buy
		padding 25px
	.how__list
		display block
	.how__item
		max-width 260px
		width 100%
		margin 0 auto 35px
		&:last-child 
			margin-bottom 0
	.section-how h3
		margin-top 15px
	.how__list:after 
		display none
	.section-head
		padding 120px 0 40px
	.section-how
		padding 40px 0
	.how-count:after
		font-weight 500
	.main-box
		flex-direction column
	.main-box 
		.main-pic
			width 100%
			max-width 280px
			&:after
				width 330px
				height 200px
		.main-text
			width 100%
			max-width: 400px
	.section-main h2
		text-align center
	.section-main p
		text-align center
	.main-svg-rows
		justify-content center
	.section-main
		padding 40px 0 70px
	.section-main h2
		margin-bottom 20px
	input::placeholder          
		font-size 12px
	input::-moz-placeholder     
		font-size 12px
	input:-moz-placeholder      
		font-size 12px
	input:-ms-input-placeholder 
		font-size 12px
	.foot-top
		display block
		margin-bottom 40px
	.section-foot h2
		margin-bottom 40px
	.foot-text
		width auto
		margin-bottom 15px
		&:last-child 
			margin-bottom 0
	.section-foot
		padding 70px 0 320px
	.footer_abs
		bottom 45px
	.order-box
		padding 25px
	.order-total
		font-size 25px
	.step__item .btn
		min-width 238px
		margin-right 0
	.box-fields_2 
		display block
		.box-field
			width auto
			margin-bottom 20px
			&:last-child 
				margin-bottom 0
	.section-inner
		padding 50px 0
	.step__item
		padding 20px
		h4:before
			width 40px
			height 40px
			font-size 20px
	.form-control
		height 50px
	.step__item.active h4
		margin-bottom 30px
// /* Small Devices, Tablets */
@media (max-width : 568px)
	.auth 
		display flex
		flex-direction column
		align-items center
	.sign-link
		margin-top 10px
		order 1
		margin-right 0
	.btn_white
		font-size 12px
	.section-head
		padding-top 150px
	.section-foot
		padding 45px 0
	.footer_abs
		position static
		background-color #1f2227
		.footer-wrap
			padding-bottom 45px
	.footer-right
		flex-direction row-reverse
	.footer-text
		padding 0
		text-align left
	.footer-logo
		position static
		margin-right 20px
// /* Small Devices, Tablets */
@media (max-width : 480px)
	.user-name
		display none
// /* Custom, iPhone Retina */
// @media (max-width : 380px)
/**/
