.header
	width 100%
	min-width $min-width
	order 1
	padding 54px 0 50px
	&_abs 
		position absolute
		z-index 1
		left 0
		top 0
		right 0
	&-wrap 
		display flex
		align-items center
		justify-content space-between
	&_inner
		padding 52px 0
		background $accent url('../img/main/header_inner-bg.png') center no-repeat;
		background-size cover

.logo	
	font-size 20px
	font-weight 900
	line-height 20px
	color $white

.sign-link
	position relative
	margin-right 26px
	color $white
	font-family $custom-font-family
	font-weight 700
	text-transform uppercase
	&:after 
		content ''
		position absolute
		left 50%
		bottom -2px
		width 100%
		height 1px
		transform translateX(-50%) scale(0) 
		background-color $white
		transition $transition
	&:hover
		&:after 
			transform translateX(-50%) scale(1) 

.user
	display flex
	align-items center
	&-name 
		margin-right 21px
		font-size 14px
		line-height 14px
		font-weight 500
		color $white
	&-link 
		position relative
		padding-right 28px
		&:after 
			content ''
			position absolute
			right 0
			top 50%
			transform translateX(-50%)
			border 4px solid transparent
			border-top 4px solid $white
			border-bottom none
	&-avatar 
		width 56px
		height 56px
		border-radius 50%
		overflow hidden
		img 
			width 100%
			height 100%
			object-fit cover
			object-position center

