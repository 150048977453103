.footer
	width 100%
	min-width $min-width
	order 3
	&_abs 
		position absolute
		left 0
		bottom 100px
		right 0
		.footer-text
			color $white
		.footer-wrap 
			padding 45px 0 0 
			border-color rgba($white, .5)
		.footer-info
			color $white
			&-links 
				a 
					color $white
	&-wrap 
		display flex
		justify-content space-between
		padding 50px 0
		border-top 1px solid #eff2fb
	&-right
		display flex
		max-width 570px
	&-info
		font-style normal
		&-links
			margin-bottom 20px
			a 
				display inline-block
				margin-right 58px
				font-weight 500
				color $text-color
				&:last-child 
					margin-right 0
	&-text
		margin-right 30px
		text-align right
	&-logo
		flex-shrink 0
		max-width 60px