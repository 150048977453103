body {
  position: relative;
  height: 100%;
  background-color: #fff;
  font-size: 15px;
  line-height: 25px;
  color: #333;
  font-weight: normal;
  font-family: 'GothamPro', sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  display: block;
  max-width: 100%;
}
input[type="text"],
input[type="email"],
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}
html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
input,
textarea {
  color: #333;
  font-family: 'GothamPro', sans-serif;
  border-radius: 0;
}
input::placeholder {
  color: rgba(51,51,51,0.5);
  font-size: 15px;
  line-height: 25px;
  font-family: 'GothamPro', sans-serif;
}
input::-moz-placeholder {
  color: rgba(51,51,51,0.5);
  font-size: 15px;
  line-height: 25px;
  font-family: 'GothamPro', sans-serif;
}
input:-moz-placeholder {
  color: rgba(51,51,51,0.5);
  font-size: 15px;
  line-height: 25px;
  font-family: 'GothamPro', sans-serif;
}
input:-ms-input-placeholder {
  color: rgba(51,51,51,0.5);
  font-size: 15px;
  line-height: 25px;
  font-family: 'GothamPro', sans-serif;
}
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
  font-family: 'GothamPro', sans-serif;
  font-size: 15px;
  line-height: 25px;
}
td {
  margin: 0px;
  padding: 0px;
}
ul {
  list-style: none;
}
form {
  padding: 0px;
  margin: 0px;
}
a {
  color: #fff;
  transition: all 0.25s ease-out;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a,
span,
div,
button {
  outline: none !important;
}
input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after,
.wrapper:after,
.row:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.loaded .main-wrapper {
  opacity: 0;
}
.icon-load {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: translate3d(0, -100%, 0);
  transition: all 0.25s ease-out;
}
.loaded .icon-load {
  transform: translate3d(0, 0%, 0);
}
.sk-folding-cube {
  width: 40px;
  height: 40px;
  transform: rotateZ(45deg);
}
.sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  background: #fff;
  transform: scale(0.96);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #19b5f2;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(0.97) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(0.97) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(0.97) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}
input::placeholder,
textarea::placeholder {
  transition: opacity 0.2s ease-out;
}
input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}
@-moz-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-o-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamProRegular/GothamProRegular.eot");
  src: local("GothamPro Regular"), url("../fonts/GothamProRegular/GothamProRegular.eot?#iefix") format('embedded-opentype'), url("../fonts/GothamProRegular/GothamProRegular.woff2") format('woff2'), url("../fonts/GothamProRegular/GothamProRegular.woff") format('woff'), url("../fonts/GothamProRegular/GothamProRegular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamProMedium/GothamProMedium.eot");
  src: local("GothamPro Medium"), url("../fonts/GothamProMedium/GothamProMedium.eot?#iefix") format('embedded-opentype'), url("../fonts/GothamProMedium/GothamProMedium.woff2") format('woff2'), url("../fonts/GothamProMedium/GothamProMedium.woff") format('woff'), url("../fonts/GothamProMedium/GothamProMedium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamProBlack/GothamProBlack.eot");
  src: local("GothamPro Black"), url("../fonts/GothamProBlack/GothamProBlack.eot?#iefix") format('embedded-opentype'), url("../fonts/GothamProBlack/GothamProBlack.woff2") format('woff2'), url("../fonts/GothamProBlack/GothamProBlack.woff") format('woff'), url("../fonts/GothamProBlack/GothamProBlack.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium.eot");
  src: local("AvenirNextCyr Medium"), url("../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium.eot?#iefix") format('embedded-opentype'), url("../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff2") format('woff2'), url("../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff") format('woff'), url("../fonts/AvenirNextCyr-Medium/AvenirNextCyr-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Bold/AvenirNextCyr-Bold.eot");
  src: local("AvenirNextCyr Bold"), url("../fonts/AvenirNextCyr-Bold/AvenirNextCyr-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/AvenirNextCyr-Bold/AvenirNextCyr-Bold.woff2") format('woff2'), url("../fonts/AvenirNextCyr-Bold/AvenirNextCyr-Bold.woff") format('woff'), url("../fonts/AvenirNextCyr-Bold/AvenirNextCyr-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?xet8t4");
  src: url("../fonts/icomoon/icomoon.eot?xet8t4#iefix") format('embedded-opentype'), url("../fonts/icomoon/icomoon.ttf?xet8t4") format('truetype'), url("../fonts/icomoon/icomoon.woff?xet8t4") format('woff'), url("../fonts/icomoon/icomoon.svg?xet8t4#icomoon") format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bitcoin:before {
  content: "\e900";
  color: #f7931a;
}
.icon-check:before {
  content: "\e901";
  color: #32c5ff;
}
.icon-done:before {
  content: "\e902";
  color: #fff;
}
.icon-dropdown:before {
  content: "\e903";
  color: #bfc0c2;
}
.icon-euro:before {
  content: "\e904";
  color: #32c5ff;
}
.icon-mastercard .path1:before {
  content: "\e905";
  color: #ff5f00;
}
.icon-mastercard .path2:before {
  content: "\e906";
  margin-left: -1.625em;
  color: #eb001b;
}
.icon-mastercard .path3:before {
  content: "\e907";
  margin-left: -1.625em;
  color: #f79e1b;
}
.icon-summary .path1:before {
  content: "\e908";
  color: #0884b5;
}
.icon-summary .path2:before {
  content: "\e909";
  margin-left: -1.5791015625em;
  color: #0da7e4;
}
.icon-summary .path3:before {
  content: "\e90a";
  margin-left: -1.5791015625em;
  color: #32c5ff;
}
.icon-summary .path4:before {
  content: "\e90b";
  margin-left: -1.5791015625em;
  color: #fff;
  opacity: 0.6;
}
.icon-summary .path5:before {
  content: "\e90c";
  margin-left: -1.5791015625em;
  color: #0da7e4;
}
.icon-summary .path6:before {
  content: "\e90d";
  margin-left: -1.5791015625em;
  color: #0da7e4;
}
.icon-summary .path7:before {
  content: "\e90e";
  margin-left: -1.5791015625em;
  color: #0da7e4;
}
.icon-summary .path8:before {
  content: "\e90f";
  margin-left: -1.5791015625em;
  color: #0da7e4;
}
.icon-summary .path9:before {
  content: "\e910";
  margin-left: -1.5791015625em;
  color: #0da7e4;
}
.icon-summary .path10:before {
  content: "\e911";
  margin-left: -1.5791015625em;
  color: #0da7e4;
}
.icon-visa:before {
  content: "\e912";
  color: #fff;
}
.footer {
  width: 100%;
  min-width: 320px;
  order: 3;
}
.footer_abs {
  position: absolute;
  left: 0;
  bottom: 100px;
  right: 0;
}
.footer_abs .footer-text {
  color: #fff;
}
.footer_abs .footer-wrap {
  padding: 45px 0 0;
  border-color: rgba(255,255,255,0.5);
}
.footer_abs .footer-info {
  color: #fff;
}
.footer_abs .footer-info-links a {
  color: #fff;
}
.footer-wrap {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  border-top: 1px solid #eff2fb;
}
.footer-right {
  display: flex;
  max-width: 570px;
}
.footer-info {
  font-style: normal;
}
.footer-info-links {
  margin-bottom: 20px;
}
.footer-info-links a {
  display: inline-block;
  margin-right: 58px;
  font-weight: 500;
  color: #333;
}
.footer-info-links a:last-child {
  margin-right: 0;
}
.footer-text {
  margin-right: 30px;
  text-align: right;
}
.footer-logo {
  flex-shrink: 0;
  max-width: 60px;
}
.header {
  width: 100%;
  min-width: 320px;
  order: 1;
  padding: 54px 0 50px;
}
.header_abs {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
}
.header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_inner {
  padding: 52px 0;
  background: #19b5f2 url("../img/main/header_inner-bg.png") center no-repeat;
  background-size: cover;
}
.logo {
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  color: #fff;
}
.sign-link {
  position: relative;
  margin-right: 26px;
  color: #fff;
  font-family: 'AvenirNextCyr', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.sign-link:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  width: 100%;
  height: 1px;
  transform: translateX(-50%) scale(0);
  background-color: #fff;
  transition: all 0.25s ease-out;
}
.sign-link:hover:after {
  transform: translateX(-50%) scale(1);
}
.user {
  display: flex;
  align-items: center;
}
.user-name {
  margin-right: 21px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #fff;
}
.user-link {
  position: relative;
  padding-right: 28px;
}
.user-link:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(-50%);
  border: 4px solid transparent;
  border-top: 4px solid #fff;
  border-bottom: none;
}
.user-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
}
.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.main-wrapper {
  padding: 0 0 0 0;
  min-width: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.wrapper {
  min-width: 320px;
  max-width: 1250px;
  padding: 0 40px 0 40px;
  margin: 0 auto;
  position: relative;
}
p {
  font-size: 20px;
  line-height: 30px;
  font-family: 'GothamPro', sans-serif;
  color: #fff;
  font-weight: 400;
  margin: 0 0 15px 0;
  padding: 0;
}
h1 {
  font-size: 50px;
  line-height: 60px;
  font-family: 'GothamPro', sans-serif;
  color: #fff;
  font-weight: 700;
  margin: 0 0 15px 0;
  padding: 0;
}
h2 {
  font-size: 50px;
  line-height: 60px;
  font-family: 'GothamPro', sans-serif;
  color: #fff;
  font-weight: 700;
  margin: 0 0 15px 0;
  padding: 0;
}
h3 {
  font-size: 30px;
  line-height: 40px;
  font-family: 'GothamPro', sans-serif;
  color: #fff;
  font-weight: 500;
  margin: 0 0 15px 0;
  padding: 0;
}
h4 {
  font-size: 20px;
  line-height: 30px;
  font-family: 'GothamPro', sans-serif;
  color: #333;
  font-weight: 500;
  margin: 0 0 15px 0;
  padding: 0;
}
.main {
  min-width: 320px;
  width: 100%;
  order: 2;
  flex-grow: 1;
  background-color: #18b5f3;
}
.main_inner {
  background-color: transparent;
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  padding: 10px 38px;
  color: #fff;
  font-weight: 700;
  border-radius: 10px;
  text-transform: uppercase;
  background-color: #19b5f2;
}
.btn:hover {
  background-color: #47c4f5;
}
.btn_button {
  border: none;
}
.btn_white {
  position: relative;
  height: 50px;
  padding: 10px 16px;
  border: 2px solid rgba(255,255,255,0.5);
  background-color: transparent;
  overflow: hidden;
}
.btn_white:after {
  content: '';
  position: absolute;
  height: 100px;
  width: 20px;
  transform: skew(-25deg) translateY(-50%);
  left: -100px;
  top: 50%;
  background-color: #fff;
}
.btn_white:hover {
  transform: translateY(-5px);
}
.btn_white:hover:after {
  left: 100%;
  transition: all 0.25s linear;
}
.section-head {
  padding: 154px 0 70px;
  position: relative;
  background: url("../img/main/head-bg.png") center no-repeat;
  background-size: cover;
}
.section-head:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 512px;
  background-image: linear-gradient(180deg, rgba(24,181,243,0) 0%, #18b5f3 100%);
}
.section-head .wrapper {
  z-index: 1;
}
.section-head h1 {
  margin-bottom: 32px;
}
.section-head p {
  margin: 0 0 53px 0;
  line-height: 35px;
}
.section-how {
  padding: 70px 0;
}
.section-how h2 {
  margin-bottom: 50px;
  text-align: center;
}
.section-how h3 {
  margin: 30px 0 0 0;
}
.section-main {
  padding: 78px 0 112px;
}
.section-main h2 {
  margin-bottom: 32px;
}
.section-main p {
  margin-bottom: 33px;
  line-height: 35px;
}
.section-main p:last-child {
  margin-bottom: 0;
}
.section-foot {
  padding: 150px 0 325px;
  background: url("../img/main/footer-bg.jpg") center no-repeat;
  background-size: cover;
}
.section-foot h2 {
  margin-bottom: 52px;
  text-align: center;
}
.section-inner {
  padding: 100px 0;
}
.head-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.head-left {
  width: calc(53% - 50px);
  padding-top: 53px;
}
.head-right {
  width: calc(47% - 50px);
}
.head-bottom {
  display: flex;
}
.head-bottom .visa-ico {
  margin-right: 16px;
  font-size: 20px;
}
.head-bottom .master-card-ico {
  margin-right: 20px;
  font-size: 24px;
}
.head-bottom__text {
  color: #fff;
}
.bitcoin-buy {
  padding: 50px 50px 40px;
  box-shadow: 0 20px 60px rgba(13,21,63,0.15);
  border-radius: 15px;
  background-color: #fff;
}
.bitcoin-row {
  display: flex;
  align-items: center;
}
.bitcoin-row .btn {
  flex-shrink: 0;
  margin-right: 34px;
}
.bitcoin-text {
  margin-top: 20px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(144,145,151,0.7);
}
.box-field {
  margin-bottom: 20px;
}
.box-field:last-child {
  margin-bottom: 0;
}
.box-field-wrap {
  margin-bottom: 30px;
}
.box-field__label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
}
.box-field__label-text {
  color: #2c2e35;
}
.box-field__label-subtext {
  color: rgba(144,145,151,0.7);
}
.box-field__input_abs {
  position: relative;
}
.box-field__input_abs .form-control {
  padding: 18px 70px 17px 20px;
}
.box-field__input_z {
  z-index: 1;
}
.box-fields {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.box-fields_1 .box-field {
  width: 100%;
}
.box-fields_2 .box-field {
  width: calc(50% - 10px);
  margin-bottom: 0;
}
.form-control {
  height: 60px;
  width: 100%;
  padding: 18px 20px 17px;
  border-radius: 10px;
  color: #2c2e35;
  border: none;
  background-color: #f5f6fa;
}
.dropdown-abs {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.how__list {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1090px;
  margin: 0 auto;
  counter-reset: item;
}
.how__list:after {
  content: '';
  position: absolute;
  top: 30px;
  left: 50%;
  height: 1px;
  width: calc(100% - 300px);
  transform: translateX(-50%);
  border: 2px dashed rgba(32,35,39,0.5);
}
.how__item {
  position: relative;
  z-index: 1;
  width: calc(33.33% - 75px);
  counter-increment: item;
  text-align: center;
}
.how-count {
  position: relative;
  height: 60px;
  width: 60px;
  margin: 0 auto;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}
.how-count:after {
  content: counter(item);
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
}
.main-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.main-box_1 .main-pic {
  padding-top: 13px;
}
.main-box_1 .main-pic:after {
  left: 49%;
  top: 60%;
  width: 537px;
  height: 315px;
  background-image: url("../img/main/schema-1.png");
}
.main-box_2 .main-pic {
  width: calc(50% - 15px);
}
.main-box_2 .main-pic:after {
  left: 49%;
  top: 51%;
  width: 571px;
  height: 308px;
  background-image: url("../img/main/schema-2.png");
}
.main-box_2 .main-text {
  padding-top: 127px;
  width: calc(50% - 60px);
}
.main-box_3 .main-pic {
  width: calc(50% - 100px);
  margin-top: -25px;
}
.main-box_3 .main-pic:after {
  top: 32%;
  left: 51%;
  width: 557px;
  height: 373px;
  background-image: url("../img/main/schema-3.png");
}
.main-box_3 .main-text {
  padding-top: 20px;
}
.main-box_reverse {
  flex-direction: row-reverse;
}
.main-box_mb {
  margin-bottom: 20px;
}
.main-text {
  width: calc(50% - 15px);
}
.main-pic {
  position: relative;
  width: calc(50% - 60px);
}
.main-pic:after {
  content: '';
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.main-pic img {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}
.main-svg-rows {
  display: flex;
}
.main-svg-rows > div {
  margin-right: 20px;
}
.main-svg-rows > div:last-child {
  margin-right: 0;
}
.visa-ico {
  font-size: 20px;
}
.master-card-ico {
  font-size: 24px;
}
.master-card-ico_mod .icon-mastercard .path1:before {
  color: #8e8ae6;
}
.master-card-ico_mod .icon-mastercard .path2:before {
  color: #005fde;
}
.master-card-ico_mod .icon-mastercard .path3:before {
  color: #e52836;
}
.foot-top {
  display: flex;
  justify-content: space-between;
  max-width: 1050px;
  margin: 0 auto 57px;
}
.foot-text {
  width: calc(50% - 70px);
  margin-bottom: 0;
  text-align: center;
}
.foot-btn {
  text-align: center;
}
.order-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.order-wrap.active .order-box {
  position: static;
  pointer-events: auto;
  opacity: 1;
  z-index: auto;
}
.order-left {
  width: calc(53% - 50px);
}
.order-right {
  width: calc(47% - 50px);
}
.order-box {
  position: absolute;
  opacity: 0;
  z-index: -111;
  pointer-events: none;
  padding: 50px;
  box-shadow: 0 30px 100px rgba(155,161,181,0.15);
  border-radius: 15px;
  background-color: #fff;
}
.order-box h4 {
  margin-bottom: 0;
}
.order-top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.order-ico {
  margin-right: 15px;
  font-size: 20px;
}
.order-table {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eff2fb;
}
.order-table table {
  width: 100%;
  color: #2c2e35;
}
.order-table table td {
  width: 50%;
  padding: 10px 0;
}
.order-table table td.mod {
  text-align: right;
  text-transform: uppercase;
}
.order-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}
.order-total__count {
  text-transform: uppercase;
}
.order-text span {
  color: #32c5ff;
}
.step__list {
  counter-reset: step;
}
.step__item {
  margin-bottom: 28px;
  counter-increment: step;
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #eff2fb;
}
.step__item:last-child {
  margin-bottom: 0;
}
.step__item.complete h4 {
  color: #333;
}
.step__item.complete h4:before {
  content: '\e901';
  font-size: 18px;
  font-family: 'icomoon';
  color: #fff;
  background-color: #23c4ff;
}
.step__item.active h4 {
  margin-bottom: 40px;
  color: #333;
}
.step__item.active h4:before {
  background-color: #23c4ff;
  color: #fff;
}
.step__item.active .step-cont {
  position: static;
  pointer-events: auto;
  opacity: 1;
  z-index: auto;
}
.step__item h4 {
  position: relative;
  margin-bottom: 0;
  padding: 10px 0 10px 70px;
  color: #d0d4e3;
  transition: all 0.25s ease-out;
}
.step__item h4:before {
  content: counter(step);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #d0d4e3;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  background-color: #f5f6fa;
  transition: all 0.25s ease-out;
}
.step__item .bitcoin-buy {
  box-shadow: none;
  padding: 0;
}
.step__item .btn {
  pointer-events: auto;
  padding: 10px 20px;
  margin-right: 28px;
  min-width: 245px;
  background-color: #19b5f2;
}
.step__item .btn:hover {
  background-color: #47c4f5;
}
.step__item .btn.freeze {
  pointer-events: none;
  background-color: rgba(25,181,242,0.5);
}
.step-cont {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: -111;
}
.icon-abs {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 5px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  background-color: #fff;
  font-size: 14px;
}
.icon-abs .icon-visa:before {
  background-image: linear-gradient(270deg, #044c9e 0%, #202565 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #044c9e;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .icon-abs .icon-visa:before {
    background: transparent;
  }
}
.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}
.checkbox + label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 0 0 0 45px;
  font-size: 12px;
  line-height: 20px;
}
.checkbox + label:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 2px solid #f5f6fa;
  transition: all 0.25s ease-out;
}
.checkbox + label:after {
  content: "\e901";
  position: absolute;
  opacity: 0;
  top: 11px;
  left: 9px;
  font-family: 'icomoon';
  font-size: 10px;
  color: #32c5ff;
  transition: all 0.25s ease-out;
}
.checkbox:checked + label:after {
  opacity: 1;
}
.jq-selectbox {
  border-radius: 8px;
}
.jq-selectbox.focused .jq-selectbox__select {
  border: none;
}
.jq-selectbox.active .jq-selectbox__select {
  box-shadow: none;
}
.jq-selectbox.opened.dropdown {
  border-radius: 8px 8px 0 0;
}
.jq-selectbox.opened.dropdown .jq-selectbox__dropdown {
  border-radius: 0 0 8px 8px;
}
.jq-selectbox.opened.dropup {
  border-radius: 0 0 8px 8px;
}
.jq-selectbox.opened.dropup .jq-selectbox__dropdown {
  border-radius: 8px 8px 0 0;
}
.jq-selectbox__select {
  padding: 7px 20px 7px 10px;
  height: 30px;
  min-width: 52px;
  border-radius: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  text-shadow: none;
}
.jq-selectbox__select:active {
  background: transparent;
  box-shadow: none;
}
.jq-selectbox__select:hover {
  background: transparent;
}
.jq-selectbox__trigger {
  width: 28px;
  border-left: none;
}
.jq-selectbox__trigger:after {
  content: '\e903';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'icomoon';
  line-height: 1;
  font-size: 5px;
  color: #bfc0c2;
}
.jq-selectbox__trigger-arrow {
  display: none;
}
.jq-file__browse,
.jq-file__name,
.jq-selectbox__select {
  font-size: 15px;
  line-height: 1;
  font-family: 'GothamPro', sans-serif;
}
.jq-selectbox__dropdown,
.jq-selectbox__search input {
  border: none;
}
.jq-selectbox__dropdown {
  font-size: 15px;
  line-height: 1;
  font-family: 'GothamPro', sans-serif;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}
.jq-selectbox li {
  min-height: 30px;
  line-height: 1;
  padding: 7px 10px;
  color: #333;
  border-radius: 0;
}
.jq-selectbox li:hover {
  background-color: #19b5f2;
}
.sel-icon_eur {
  background: #fff url("../img/icons/euro.svg") no-repeat 10px 6px;
  background-size: 16px;
}
.sel-icon_btc {
  background: #fff url("../img/icons/bitcoin.svg") no-repeat 10px 4px;
  background-size: 16px;
}
@media (max-width: 1280px) {
  .bitcoin-row {
    display: block;
  }
  .bitcoin-row {
    text-align: center;
  }
  .bitcoin-row .btn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .bitcoin-text {
    text-align: center;
  }
  .box-field__label {
    flex-direction: column;
  }
}
@media (max-width: 1100px) {
  .how__list:after {
    width: calc(100% - 230px);
  }
  .order-left,
  .order-right {
    width: calc(50% - 25px);
  }
}
@media (max-width: 991px) {
  .wrapper {
    padding: 0 20px;
  }
  .header {
    padding: 25px 0;
  }
  .section-head {
    padding: 120px 0 60px;
  }
  .head-wrap {
    display: block;
  }
  .head-left {
    width: auto;
    margin-bottom: 30px;
    padding-top: 0;
  }
  .head-right {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
  h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .how__item {
    width: calc(33.33% - 0px);
  }
  .main-box_3 .main-pic {
    margin-top: 0;
  }
  .main-box_2 .main-text,
  .main-box_3 .main-text,
  .main-box_1 .main-pic {
    padding-top: 0;
  }
  .main-box {
    align-items: center;
    margin-bottom: 50px;
  }
  .main-box:last-child {
    margin-bottom: 0;
  }
  .main-box_3 .main-pic {
    width: calc(50% - 40px);
  }
  .foot-text {
    width: calc(50% - 20px);
  }
  .section-foot {
    padding: 100px 0 370px;
  }
  .footer-wrap {
    flex-wrap: wrap;
  }
  .footer-right {
    position: relative;
    order: -1;
    margin-bottom: 30px;
    width: 100%;
    max-width: 100%;
  }
  .footer-text {
    text-align: center;
    padding: 0 70px;
    margin-right: 0;
  }
  .footer-logo {
    position: absolute;
    top: 0;
    right: 0;
  }
  .footer-left {
    margin: 0 auto;
    text-align: center;
  }
  .footer-text br {
    display: none;
  }
  .how__list:after {
    width: calc(100% - 260px);
  }
  .order-wrap {
    flex-direction: column-reverse;
    align-items: inherit;
  }
  .order-left,
  .order-right {
    width: auto;
  }
  .order-right {
    margin-bottom: 50px;
  }
  .section-inner {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 40px;
  }
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
  h3 {
    font-size: 20px;
    line-height: 30px;
  }
  h4 {
    font-size: 16px;
    line-height: 26px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .section-head p {
    line-height: 28px;
  }
  .head-bottom {
    flex-wrap: wrap;
  }
  .bitcoin-buy {
    padding: 25px;
  }
  .how__list {
    display: block;
  }
  .how__item {
    max-width: 260px;
    width: 100%;
    margin: 0 auto 35px;
  }
  .how__item:last-child {
    margin-bottom: 0;
  }
  .section-how h3 {
    margin-top: 15px;
  }
  .how__list:after {
    display: none;
  }
  .section-head {
    padding: 120px 0 40px;
  }
  .section-how {
    padding: 40px 0;
  }
  .how-count:after {
    font-weight: 500;
  }
  .main-box {
    flex-direction: column;
  }
  .main-box .main-pic {
    width: 100%;
    max-width: 280px;
  }
  .main-box .main-pic:after {
    width: 330px;
    height: 200px;
  }
  .main-box .main-text {
    width: 100%;
    max-width: 400px;
  }
  .section-main h2 {
    text-align: center;
  }
  .section-main p {
    text-align: center;
  }
  .main-svg-rows {
    justify-content: center;
  }
  .section-main {
    padding: 40px 0 70px;
  }
  .section-main h2 {
    margin-bottom: 20px;
  }
  input::placeholder {
    font-size: 12px;
  }
  input::-moz-placeholder {
    font-size: 12px;
  }
  input:-moz-placeholder {
    font-size: 12px;
  }
  input:-ms-input-placeholder {
    font-size: 12px;
  }
  .foot-top {
    display: block;
    margin-bottom: 40px;
  }
  .section-foot h2 {
    margin-bottom: 40px;
  }
  .foot-text {
    width: auto;
    margin-bottom: 15px;
  }
  .foot-text:last-child {
    margin-bottom: 0;
  }
  .section-foot {
    padding: 70px 0 320px;
  }
  .footer_abs {
    bottom: 45px;
  }
  .order-box {
    padding: 25px;
  }
  .order-total {
    font-size: 25px;
  }
  .step__item .btn {
    min-width: 238px;
    margin-right: 0;
  }
  .box-fields_2 {
    display: block;
  }
  .box-fields_2 .box-field {
    width: auto;
    margin-bottom: 20px;
  }
  .box-fields_2 .box-field:last-child {
    margin-bottom: 0;
  }
  .section-inner {
    padding: 50px 0;
  }
  .step__item {
    padding: 20px;
  }
  .step__item h4:before {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  .form-control {
    height: 50px;
  }
  .step__item.active h4 {
    margin-bottom: 30px;
  }
}
@media (max-width: 568px) {
  .auth {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sign-link {
    margin-top: 10px;
    order: 1;
    margin-right: 0;
  }
  .btn_white {
    font-size: 12px;
  }
  .section-head {
    padding-top: 150px;
  }
  .section-foot {
    padding: 45px 0;
  }
  .footer_abs {
    position: static;
    background-color: #1f2227;
  }
  .footer_abs .footer-wrap {
    padding-bottom: 45px;
  }
  .footer-right {
    flex-direction: row-reverse;
  }
  .footer-text {
    padding: 0;
    text-align: left;
  }
  .footer-logo {
    position: static;
    margin-right: 20px;
  }
}
@media (max-width: 480px) {
  .user-name {
    display: none;
  }
}
